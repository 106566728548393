import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { BASE_URL } from "../../url/baseurl";
import print from "print-js";
import { user_session_data } from "../../services/ReportCategoryApi";
import { report_data, report_group_location_api, custgroup_location_api } from "../../services/ReportCategoryApi";
import $ from "jquery";
import axios from "axios";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import { message } from "antd";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import { Tooltip } from "antd";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Multiselect from 'multiselect-react-dropdown';
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
// import { Col, Form } from "react-bootstrap";
// import Select from "react-select";
const theme = localStorage.getItem('brandColor');

const StyledButton = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;
const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },
  },
});




function Ecompleted_report() {
  $(document).on("change", ".select-all1", function () {
    if ($(this).is(":checked")) {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", true);
      });
    } else {
      $('[name="check[1]"]').each(function () {
        $(this).prop("checked", false);
      });
    }
  });

  $(document).on("change", ".select-all2", function () {
    if ($(this).is(":checked")) {
      $('[name="check[2]"]').each(function () {
        $(this).prop("checked", true);
      });
    } else {
      $('[name="check[2]"]').each(function () {
        $(this).prop("checked", false);
      });
    }
  });

  $(document).on("change", ".select-all3", function () {
    if ($(this).is(":checked")) {
      $('[name="check[3]"]').each(function () {
        $(this).prop("checked", true);
      });
    } else {
      $('[name="check[3]"]').each(function () {
        $(this).prop("checked", false);
      });
    }
  });

  // --------------------- Sorting Hooks ----------------------
  const [sortBySno, setSortBySno] = useState(true);
  const [sortByTopic, setSortByTopic] = useState(false);
  const [sortByLocation, setSortByLocation] = useState(false);
  const [sortByCountry, setSortByCountry] = useState(false);
  const [sortByPreviousPeriod, setSortByPreviousPeriod] = useState(false);
  const [sortByLatestPeriod, setSortByLatestPeriod] = useState(false);
  const [sortRev, setSortRev] = useState(false);
  const [sortType, setSortType] = useState(false);

  const [userid, setUserid] = useState(localStorage.getItem("UserID"));
  const [userData, setUserData] = useState("");
  const [list, setList] = useState("");
  const [cust_id, setCust_id] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [locationList, setLocationList] = useState("");
  const [topicList, setTopicList] = useState("");
  const [descGroup, setDescGroup] = useState([]);
  const [groupid, setGroupid] = useState("");
  const [selectedOptions, setSelectedOptions] = useState(false);
  const [field, setField] = useState([]);
  const [reportgroup, setReportgroup] = useState("");
  const [reportgroup_json, setReportgroup_json] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [pageSize1, setPageSize1] = useState(4);



  const [reportStartDate, setReportStartDate] = useState(new Date(new Date().getFullYear(), 0, 1)); // Default Start Date: Jan 1 of current year
  const [reportEndDate, setReportEndDate] = useState(new Date()); // Default End Date: Today's Date



  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const value = location.state?.value;
  const [report, setReport] = useState(value);

  const getUserSessionData = async () => {
    user_session_data(userid)
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);

          setUserData(res.data.userdata);
          setList(res.data.list);
          setCust_id(res.data.userdata[0].customerid);

          console.log("User Data ->", userData);
          console.log("List ->", list);
          console.log("Customer ID ->", cust_id);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("something went wrong");
      });
  };

  const getReportData = async () => {
    report_data({
      userid: userid,
      customerid: cust_id,
    })
      .then((res) => {
        if (res.data.code === 200) {
          console.log(res.data);

          setCountryList(res.data.Countrylist);
          // setLocationList(res.data.Locationlist);
          setTopicList(res.data.Topiclist);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("something went wrong");
      });
  };

  const getReportlocation = async () => {
    report_group_location_api({
      customerid: cust_id,

    })

      .then((res) => {
        if (res.data.code == 200) {
          console.log("res group data===>>", res.data);
          setDescGroup(res.data.result);
          console.log("res group data===>>", res.data.groupid);
          setGroupid(res.data.groupid[0].custgroupid)

          //  }
          console.log("gid", groupid);
        } else {
          console.log("error");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };

  const getgrouplocation = async () => {
    console.log("id", reportgroup)
    custgroup_location_api({
      customerid: cust_id,
      Custgroupid: reportgroup,
      Groupid: groupid,
    })

      .then((res) => {
        if (res.data.code == 200) {
          console.log("res group data===>>", res.data);
          setLocationList(res.data.result);
          // console.log("res group data===>>", res.data.result[0].custgroupid);
          // setGroupid(res.data.result[0].custgroupid);

        } else {
          console.log("error");
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("error===>>", err);
      });
  };

  useEffect(() => {
    getUserSessionData();
  }, []);

  useEffect(() => {
    getReportData();
    getReportlocation();
    getgrouplocation();
  }, [cust_id]);



  // hooks for table data name
  const [csv_table_type, setCsv_table_type] = useState("");

  // forming data for country loc topic

  const [country_data, setCountry_data] = useState([]);
  const [country_data_json, setCountry_data_json] = useState([]);
  const [loc_data, setLoc_data] = useState([]);
  const [loc_data_json, setLoc_data_json] = useState([]);
  const [topic_data, setTopic_data] = useState([]);
  const [topic_data_json, setTopic_data_json] = useState([]);

  //hooks for conditional table rendering report button click
  const [run, setRun] = useState("");

  //sending data run report func

  const [report_res, setReport_res] = useState([]);
  const [report_topictaken, setReport_topictaken] = useState([]);

  const run_report_api = async () => {
    let data = {
      customerid: cust_id,
      Countrylist: country_data,
      Locationlist: loc_data_json,
      Topiclist: topic_data_json,
    };
    let res = await axios.put(
      BASE_URL +
      `/api/run_report`,
      data
    );
    if (res.data.code == 200) {
      // message.success("Successfully !", 1);
      setReport_res(res.data.Topicsum);
      setReport_topictaken(res.data.Topictaken);

      setRun("run_report");
      console.log("data==>", res.data);
    } else {
      console.log("error==>", res);
      message.error(res.data.message);
    }
  };

  const [PDT_report, set_PDT_report] = useState([]);
  const [PDT_bk_report, set_PDT_bk_report] = useState([]);
  const [emailTopic, setEmailTopic] = useState([]);
  const pastDueTopics_report_api = async () => {
    let data = {
      customerid: cust_id,
      Countrylist: country_data,
      Locationlist: loc_data_json,
      Topiclist: topic_data_json,
    };
    let res = await axios.post(
      BASE_URL +
      `/api/past_due_topics_report`,
      data
    );
    if (res.data.code == 200) {
      set_PDT_report(res.data.report);
      set_PDT_bk_report(res.data.report)
      setEmailTopic(res.data.emailtopic)
      setRun("past_due_topics");
      console.log("data==>", res.data);
    } else {
      console.log("error==>", res);
      message.error(res.data.message);
    }
  }
  const past_due_topics_email_report_api = async () => {
    let data = {
      customerid: cust_id,
      pastdueReport: PDT_report,
    }
    let res = await axios.post(
      BASE_URL +
      `/api/past_due_topics_email_report`,
      data
    );
    if (res.data.code == 200) {
      console.log("error==>", res);
      message.success(res.data.message);
    } else if (res.data.code == 400) {
      message.error(res.data.message);
    }
    console.log("email", data);
  }

  const [AST_report_emailsugg, set_AST_report_emailsugg] = useState([]);
  const [AST_report, set_AST_report] = useState([]);
  const [AST_bk_report, set_AST_bk_report] = useState([]);
  const assignedTasks_report_api = async () => {
    let data = {
      customerid: cust_id,
      Countrylist: country_data,
      Locationlist: loc_data_json,
      Topiclist: topic_data_json,
    };
    let res = await axios.post(
      BASE_URL +
      `/api/assigned_tasks_topics_report`,
      data
    );
    if (res.data.code == 200) {
      set_AST_report(res.data.report);
      set_AST_report_emailsugg(res.data.email[0]);
      set_AST_bk_report(res.data.report)
      setRun("assigned_tasks_topics");
      console.log("data==>", res.data);


      // console.log("data==>", AST_report); 
    } else {
      console.log("error==>", res);
      message.error(res.data.message);
    }
  }

  const Assigned_tasks_email_api = async () => {
    let data = {
      customerid: cust_id,
      assignedReport: AST_report,
    }
    let res = await axios.post(
      BASE_URL +
      `/api/assigned_tasks_email_report`,
      data
    );
    if (res.data.code == 200) {
      console.log("error==>", res);
      message.success(res.data.message);
    }
    console.log("email", data);
  }




  const [OST_report, set_OST_report] = useState([]);
  const [OST_bk_report, set_OST_bk_report] = useState([]);

  const _onclick_check_box = async (e) => {
    setCountry_data_json(e.target.checked);
    setCountry_data(e.target.checked);
  };


  const outstanding_tasks_topics_report = async () => {
    let data = {
      customerid: cust_id,
      Countrylist: country_data,
      Locationlist: loc_data_json,
      Topiclist: topic_data_json,
    };
    let res = await axios.post(
      BASE_URL +
      `/api/outstanding_tasks_topics_report`,
      data
    );

    // let res = await axios.post(
    //   BASE_URL +
    //   `/api/assigned_tasks_topics_report`,
    //   data
    // );
    if (res.data.code == 200) {
      set_OST_report(res.data.outreport);
      console.log("OST", OST_report);
      // set_AST_report_emailsugg(res.data.email[0]);
      set_OST_bk_report(res.data.outdetails)
      console.log("OST", res.data.outreport)
      setRun("outstanding_tasks_topics");
      console.log("data==>", res.data);


      // console.log("data==>", AST_report); 
    } else {
      console.log("error==>", res);
      message.error(res.data.message);
    }
  }



  const [CST_report, set_CST_report] = useState([]);
  const [CST_bk_report, set_CST_bk_report] = useState([]);


  const formattedReportStartDate = reportStartDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  
  // Convert reportEndDate to desired date format
  const formattedReportEndDate = reportEndDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const completed_tasks_topics_report = async () => {
    let data = {
      customerid: cust_id,
      Countrylist: country_data,
      Locationlist: loc_data_json,
      Topiclist: topic_data_json,
      reportStartDate: formattedReportStartDate,
      reportEndDate: formattedReportEndDate,
    };
    // let res = await axios.post(
    //   BASE_URL +
    //   `/api/outstanding_tasks_topics_report`,
    //   data
    // );

    let res = await axios.post(
      BASE_URL +
      `/api/completed_tasks_topics_report`,
      data
    );
    if (res.data.code == 200) {
      set_CST_report(res.data.completereport);
      //set_AST_report_emailsugg(res.data.email[0]);
      set_CST_bk_report(res.data.completereportall)
      setRun("completed_tasks_topics");
      console.log("data==>", res.data);


      // console.log("data==>", AST_report); 
    } else {
      console.log("error==>", res);
      message.error(res.data.message);
    }
  }


  // dry report func api call

  const [drill_report_data, setDrill_report_data] = useState([]);
  const drill_report_api = async () => {
    let data = {
      Topictaken: report_topictaken,
      Locationlist: locationList,
      Topiclist: topicList,
      customerid: cust_id,
    };
    let res = await axios.put(BASE_URL + "/api/drill_down", data);
    if (res.data.code == 200) {
      // setDrill_report_data(res.data.Topicdet)
      // message.success("Successful !")
      setRun("drill_report");
      console.log("drill report data==>", res.data);
    } else {
      // message.error("Something went wrong !")
      console.log("error==>", res);
    }

    if (sortBySno) {
      setDrill_report_data(
        sortRev ? res.data.Topicdet?.reverse() : res.data.Topicdet
      );
    } else if (sortByTopic) {
      setDrill_report_data(
        sortRev
          ? res.data.Topicdet?.sort((a, b) => {
            const x = a.cuzdesc.toUpperCase();
            const y = b.cuzdesc.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })?.reverse()
          : res.data.Topicdet?.sort((a, b) => {
            const x = a.cuzdesc.toUpperCase();
            const y = b.cuzdesc.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })
      );
    } else if (sortByLocation) {
      setDrill_report_data(
        sortRev
          ? res.data.Topicdet?.sort((a, b) => {
            const x = a.locationdesc.toUpperCase();
            const y = b.locationdesc.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })?.reverse()
          : res.data.Topicdet?.sort((a, b) => {
            const x = a.locationdesc.toUpperCase();
            const y = b.locationdesc.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })
      );
    } else if (sortByCountry) {
      setDrill_report_data(
        sortRev
          ? res.data.Topicdet?.sort((a, b) => {
            const x = a.countrycode.toUpperCase();
            const y = b.countrycode.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })?.reverse()
          : res.data.Topicdet?.sort((a, b) => {
            const x = a.countrycode.toUpperCase();
            const y = b.countrycode.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
          })
      );
    } else if (sortByPreviousPeriod) {
      setDrill_report_data(
        sortRev
          ? res.data.Topicdet?.sort(
            (a, b) => a.previousperiod - b.previousperiod
          )?.reverse()
          : res.data.Topicdet?.sort(
            (a, b) => a.previousperiod - b.previousperiod
          )
      );
    } else if (sortByLatestPeriod) {
      setDrill_report_data(
        sortRev
          ? res.data.Topicdet?.sort(
            (a, b) => a.latestperiod - b.latestperiod
          )?.reverse()
          : res.data.Topicdet?.sort((a, b) => a.latestperiod - b.latestperiod)
      );
    } else {
      setDrill_report_data(res.data.Topicdet);
    }
  };

  useEffect(() => {
    drill_report_api();
    console.log("data sort", drill_report_data);
  }, [
    sortBySno,
    sortByTopic,
    sortRev,
    sortByLocation,
    sortByCountry,
    sortByLatestPeriod,
    sortByPreviousPeriod,
  ]);

  // csv download function

  function download_csv_drill_report() {
    let table_header = {
      cuzdesc: "Topic",
      locationdesc: "Location",
      custgroupdesc: "Reporting Grp",
      custlocid: "Internal Loc",
      countrycode: "Country",
      previousperiod: "Previous Period",
      latestperiod: "Latest Period",
      assignuser: "Assigned Users",
      hinon: "High Not Completed",
      hitotal: "High Total",
      mednon: "Medium Not Completed",
      medtotal: "Medium Total",
      allrisknon: "All Not Completed",
      allrisktotal: "All Total",
    };
    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = drill_report_data
      ?.map(function (d) {
        let temp = {
          cuzdesc: d.cuzdesc,
          locationdesc: d.locationdesc.replace("#", ""),
          custgroupdesc: String(d.custgroupdesc).replace("#", ""),
          custlocid: String(d.custlocid).replace("#", ""),
          countrycode: d.countrycode,
          previousperiod: d.previousperiod,
          latestperiod: d.latestperiod,
          assignuser: d.assignuser,
          hinon: d.hinon,
          hitotal: d.hitotal,
          mednon: d.mednon,
          medtotal: d.medtotal,
          allrisknon: d.allrisknon,
          allrisktotal: d.allrisktotal,
        };
        return JSON.stringify(Object.values(temp));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "drill_report.csv";
    hiddenElement.click();
  }

  function download_csv_run_report() {
    let table_header = {
      cuzdesc: "Topic",
      locationcount: "Location",
      countrycount: "Country",
      testcount: "Quizzes Completed",
      lastcount: "Quizzes Last Month",
      assignedcount: "Assigned Users",
      highnon: "High Not Completed",
      hightotal: "High Total",
      mediumnon: "Medium Not Completed",
      mediumtotal: "Medium Total",
      allnon: "All Not Completed",
      alltotal: "All Total",
    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");
    console.log(report_res, "report_res");

    var csv = report_res
      ?.map(function (d) {
        if (csv_table_type === "run_report") {
          let temp = {
            cuzdesc: d.cuzdesc,
            locationcount: d.locationcount,
            countrycount: d.countrycount,
            testcount: d.testcount,
            lastcount: d.lastcount,
            assignedcount: d.assignedcount,
            highnon: d.highnon,
            hightotal: d.hightotal,
            mediumnon: d.mediumnon,
            mediumtotal: d.mediumtotal,
            allnon: d.allnon,
            alltotal: d.alltotal,
          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;
    console.log(temporary, "temporary");


    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "run_report.csv";
    hiddenElement.click();
  }

  function download_csv_PDT_report() {
    let table_header = {
      cuzdesc: "Topic",
      locationdesc: "Location",
      custgroupdesc: "Reporting Grp",
      custlocid: "Internal Loc",
      countrycode: "Country",
      periodcode: "Last Period",
      aged_months: "Aged Months",
      emailsend: "Email Last Sent"
    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = PDT_report
      ?.map(function (d) {
        if (csv_table_type === "past_due_topics") {
          let temp = {
            cuzdesc: d.cuzdesc,
            locationdesc: d.locationdesc.replace('#', ''),
            custgroupdesc: String(d.custgroupdesc).replace("#", ""),
            custlocid: String(d.custlocid).replace("#", ""),
            countrycode: d.countrycode,
            periodcode: d.periodcode,
            aged_months: d.aged_months,
            emailsend: d.emailsend
          };

          return JSON.stringify(Object.values(temp));
        }


        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$#)/gm, "");

    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "past_due_topics_report.csv";
    hiddenElement.click();
  }

  //Assigned Task 
  function download_csv_AST_report() {
    let table_header = {
      topicdesc: "Topic",
      locationdesc: "Location",
      custgroupdesc: "Reporting Grp",
      custlocid: "Internal Loc",
      countrycode: "Country",
      qownerid: "Assigned To",
      solutiondesc: "Solution",
      prioritydesc: "Priority",
      qduedate: "Due Date",
      pastdue: "Days Past Due",

    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = AST_report
      ?.map(function (d) {
        if (csv_table_type === "assigned_tasks_topics") {
          let temp = {
            topicdesc: d.topicdesc,
            locationdesc: d.locationdesc.replace("#", ""),
            custgroupdesc: String(d.custgroupdesc).replace("#", ""),
            custlocid: String(d.custlocid).replace("#", ""),
            countrycode: d.countrycode,
            qownerid: d.qownerid,
            solutiondesc: d.solutiondesc,
            prioritydesc: d.prioritydesc,
            qduedate: d.qduedate,
            pastdue: d.pastdue,

          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Assigned_topics_report.csv";
    hiddenElement.click();
  }




  //outstanding Task 
  function download_csv_OST_report() {
    let table_header = {
      locationdesc: "Location",
      topicdesc: "Topic",
      countrycode: "Country",
      perioddesc: "Period",
      totalcount: "Total Outstanding",
      high: "Priority High",
      medium: "Priority Medium",
      low: "Priority Lowest",

    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = OST_report
      ?.map(function (d) {
        if (csv_table_type === "Outstanding Tasks") {
          let temp = {
            locationdesc: d.locationdesc.replace("#", ""),
            topicdesc: d.topicdesc,
            countrycode: d.countrycode,
            perioddesc: d.perioddesc,
            totalcount: d.totalcount,
            high: d.high,
            medium: d.medium,
            low: d.low,

          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Outstanding_Tasks_Summary_Report.csv";
    hiddenElement.click();
  }

  //outstanding Task 
  function download_csv_OST_drill_report() {
    let table_header = {
      locationdesc: "Location",
      topicdesc: "Topic",
      countrycode: "Country",
      perioddesc: "Period",
      solutiondesc: "Solution",
      prioritydesc: "Priority",


    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = OST_bk_report
      ?.map(function (d) {
        if (csv_table_type === "Outstanding Tasks details") {
          let temp = {
            locationdesc: d.locationdesc.replace("#", ""),
            topicdesc: d.topicdesc,
            countrycode: d.countrycode,
            perioddesc: d.perioddesc,
            solutiondesc: d.solutiondesc,
            prioritydesc: d.prioritydesc,


          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Outstanding_Tasks_Details_Report.csv";
    hiddenElement.click();
  }

  function download_csv_CST_report() {
    let table_header = {
      locationdesc: "Location",
      topicdesc: "Topic",
      countrycode: "Country",
      perioddesc: "Period",
      totalcount: "Total Completed",
      high: "Priority High",
      medium: "Priority Medium",
      low: "Priority Lowest",

    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = CST_report
      ?.map(function (d) {
        if (csv_table_type === "Completed Tasks") {
          let temp = {
            locationdesc: d.locationdesc.replace("#", ""),
            topicdesc: d.topicdesc,
            countrycode: d.countrycode,
            perioddesc: d.perioddesc,
            totalcount: d.totalcount,
            high: d.high,
            medium: d.medium,
            low:d.low,

          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Completed_Tasks_Summary_Report.csv";
    hiddenElement.click();
  }

  function download_csv_CST_drill_report() {
    let table_header = {
      locationdesc: "Location",
      topicdesc: "Topic",
      countrycode: "Country",
      perioddesc: "Period",
      updated_at: "Completed On ",
      solutiondesc: "Solution",
      prioritydesc: "Priority",


    };

    let hprepaiire1 = JSON.stringify(Object.values(table_header));
    let varrr = hprepaiire1 + "\n";
    let vaarrsfs = varrr.replace(/(^\[)|(\]$)/gm, "");

    var csv = CST_bk_report
      ?.map(function (d) {
        if (csv_table_type === "Completed Tasks details") {
          let temp = {
            locationdesc: d.locationdesc.replace("#", ""),
            topicdesc: d.topicdesc,
            countrycode: d.countrycode,
            perioddesc: d.perioddesc,
            updated_at: d.updated_at,
            solutiondesc: d.solutiondesc,
            prioritydesc: d.prioritydesc,


          };
          return JSON.stringify(Object.values(temp));
        }

        return JSON.stringify(Object.values(d));
      })
      .join("\n")
      .replace(/(^\[)|(\]$)/gm, "");
    let temporary = vaarrsfs + csv;

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(temporary);
    hiddenElement.target = "_blank";
    hiddenElement.download = "Completed_Tasks_Details_Report.csv";
    hiddenElement.click();
  }


  const print_drillReport = () => {
    print({
      printable: drill_report_data,
      properties: [
        { field: "cuzdesc", displayName: "Topic" },
        { field: "locationdesc", displayName: "Location" },
        { field: "custgroupdesc", displayName: "Reporting Grp" },
        { field: "custlocid", displayName: "Internal Loc" },
        { field: "countrycode", displayName: "Country" },
        { field: "previousperiod", displayName: "Previous Period" },
        { field: "latestperiod", displayName: "Latest Period" },
        { field: "assignuser", displayName: "Assigned Users" },
        { field: "hinon", displayName: "High Not Completed" },
        { field: "hitotal", displayName: "High Total" },
        { field: "mednon", displayName: "Medium Not Completed" },
        { field: "medtotal", displayName: "Medium Total" },
        { field: "allrisknon", displayName: "All Not Completed" },
        { field: "allrisktotal", displayName: "All Total" },
      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };

  const print_runReport = () => {
    print({
      printable: report_res,
      properties: [
        { field: "cuzdesc", displayName: "Topic" },
        { field: "locationcount", displayName: "Location" },
        { field: "countrycount", displayName: "Country" },
        { field: "testcount", displayName: "Quizzes Completed" },
        { field: "", displayName: "Quizzes Last Month" },
        { field: "assignedcount", displayName: "Assigned Users" },
        { field: "highnon", displayName: "High Not Completed" },
        { field: "hightotal", displayName: "High Total" },
        { field: "mediumnon", displayName: "Medium Not Completed" },
        { field: "mediumtotal", displayName: "Medium Total" },
        { field: "allnon", displayName: "All Not Completed" },
        { field: "alltotal", displayName: "All Total" },
      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };

  const print_PDTReport = () => {
    print({
      printable: PDT_report,
      properties: [
        { field: "cuzdesc", displayName: "Topic" },
        { field: "locationdesc", displayName: "Location" },
        { field: "custgroupdesc", displayName: "Reporting Grp" },
        { field: "custlocid", displayName: "Internal Loc" },
        { field: "countrycode", displayName: "Country" },
        { field: "periodcode", displayName: "Last Period" },
        { field: "aged_months", displayName: "Aged Months" },
        { field: "emailsend", displayName: "Email Last Sent" },
      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };
  const print_ASTReport = () => {
    print({
      printable: AST_report,
      properties: [
        { field: "topicdesc", displayName: "Topic" },
        { field: "locationdesc", displayName: "Location" },
        { field: "custgroupdesc", displayName: "Reporting Grp" },
        { field: "custlocid", displayName: "Internal Loc" },
        { field: "countrycode", displayName: "Country" },
        { field: "qownerid", displayName: "Assigned To" },
        { field: "solutiondesc", displayName: "Solution" },
        { field: "prioritydesc", displayName: "Priority" },
        { field: "qduedate", displayName: "Due Date" },
        { field: "pastdue", displayName: "Days Past Due" },
      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };

  const print_OSTReport = () => {
    print({
      printable: OST_report,
      properties: [
        { field: "locationdesc", displayName: "Location" },
        { field: "topicdesc", displayName: "Topic" },
        { field: "countrycode", displayName: "Country" },
        { field: "perioddesc", displayName: "Period" },
        { field: "totalcount", displayName: "Total Outstanding" },
        { field: "high", displayName: "Priority High " },
        { field: "medium", displayName: "Priority Medium" },
        { field: "low", displayName: "Priority Lowest" },

      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };


  const print_OST_detailsReport = () => {
    print({
      printable: OST_bk_report,
      properties: [
        { field: "locationdesc", displayName: "Location" },
        { field: "topicdesc", displayName: "Topic" },
        { field: "countrycode", displayName: "Country" },
        { field: "perioddesc", displayName: "Period" },
        { field: "solutiondesc", displayName: "Solution" },
        { field: "prioritydesc", displayName: "Priority" },

      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };

  const print_CSTReport = () => {
    print({
      printable: CST_report,
      properties: [
        { field: "locationdesc", displayName: "Location" },
        { field: "topicdesc", displayName: "Topic" },
        { field: "countrycode", displayName: "Country" },
        { field: "perioddesc", displayName: "Period" },
        { field: "totalcount", displayName: "Total Completed" },
        { field: "high", displayName: "Priority High " },
        { field: "medium", displayName: "Priority Medium" },
        { field: "low", displayName: "Priority Lowest" },

      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };

  const print_CST_detailsReport = () => {
    print({
      printable: CST_bk_report,
      properties: [
        { field: "locationdesc", displayName: "Location" },
        { field: "topicdesc", displayName: "Topic" },
        { field: "countrycode", displayName: "Country" },
        { field: "perioddesc", displayName: "Period" },
        { field: "updated_at", displayName: "Completed On" },
        { field: "solutiondesc", displayName: "Solution" },
        { field: "prioritydesc", displayName: "Priority" },

      ],
      type: "json",
      gridHeaderStyle: "color: red;  border: 2px solid #3971A5;",
      gridStyle: "border: 2px solid #3971A5;",
    });
  };


  const onSort = (key, array) => {
    if (key == 'sno') {
      set_PDT_report(array.reverse());

    } else if (key == "aged_months") {
      let sortedArray = array?.sort((a, b) => {
        const x = a[key];
        const y = b[key];

        if (x === 'None') {
          return -1;
        }

        if (y === 'None') {
          return 1;
        }

        if (x === y) {
          return 0;
        }

        return x < y ? 1 : -1;
        //return x === y ? 0 : x > y ? 1 : -1;
      });
      set_PDT_report(sortType ? sortedArray.reverse() : sortedArray);
    } else if (key == 'emailsent') {

      let sortedArray = array?.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return x - y;
        //return x === y ? 0 : x > y ? 1 : -1;
      });
      set_PDT_report(sortType ? sortedArray.reverse() : sortedArray);
    }
    else {
      let sortedArray = array?.sort((a, b) => {
        const x = a[key].toLowerCase();
        const y = b[key].toLowerCase();
        return x === y ? 0 : x > y ? 1 : -1;
      });
      console.log(sortedArray, "sortedArray")
      set_PDT_report(sortType ? sortedArray.reverse() : sortedArray);
    }

  }

  const ASTsort = (key, array) => {
    if (key == 'sno') {
      set_AST_report(array.reverse());
    }
    else if (key == 'pastdue') {
      let sortedArray = array?.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        return x === y ? 0 : x > y ? 1 : -1;
      });
      set_AST_report(sortType ? sortedArray.reverse() : sortedArray);
    }
    else {
      let sortedArray = array?.sort((a, b) => {
        const x = a[key].toLowerCase();
        const y = b[key].toLowerCase();
        return x === y ? 0 : x > y ? 1 : -1;
      });
      console.log(sortedArray, "sortedArray")
      set_AST_report(sortType ? sortedArray.reverse() : sortedArray);
    }

  }

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value ===
      "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" &&
      event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter(o => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }


  let SNo = 1;
  const rowsWithSerialNumber = AST_report.map((AST_report) => {
    return {
      ...AST_report,
      SNo: SNo++,
    };
  });

  const rows1 = rowsWithSerialNumber;

  const columnsAssign: GridColumns = [

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "topicdesc",
      headerName: "Topic",
      // minwidth: 200,
      flex: 1.3,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 26
                ? `${params.value.substring(0, 26)}...`
                : params.value}
          </Tooltip>
        );
      },

    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "number",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 26
                ? `${params.value.substring(0, 26)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "qownerid",
      headerName: "Assigned To",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1.3,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 26
                ? `${params.value.substring(0, 26)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "solutiondesc",
      headerName: "Solution",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 26
                ? `${params.value.substring(0, 26)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
    {
      field: "prioritydesc",
      headerName: "Priority",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
    {
      field: "qduedate",
      headerName: "Due Date",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      //editable: true,
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "pastdue",
      headerName: "Days Past Due",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
  ];


  let SNo1 = 1;
  const rowsWithSerialNumber1 = OST_report.map((OST_report) => {
    return {
      ...OST_report,
      SNo1: SNo1++,
    };
  });

  // const rowsWithSerialNumber1 = OST_report.map((OST_report, index) => {
  //   return {
  //     ...OST_report,
  //     SNo1: index + 1,
  //   };
  // });




  const rows2 = rowsWithSerialNumber1;

  const columnsOutstanding: GridColumns = [

    {
      field: "SNo1",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "locationdesc",
      headerName: "Location",
      // minwidth: 200,
      flex: 1.3,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },

    },
    {
      field: "topicdesc",
      headerName: "Topic",
      // width: 200,
      // type: "number",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "perioddesc",
      headerName: "Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },



    },
    {
      field: "totalcount",
      headerName: "Total Outstanding",
      type: "number",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },

      //editable: true,
    },
    {
      field: "high",
      headerName: "Priority High",
      type: "number",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
    {
      field: "medium",
      headerName: "Priority Medium",
      type: "number",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
    {
      field: "low",
      headerName: "Priority Lowest",
      type: "number",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },
  ];


  let SNo2 = 1;
  const rowsWithSerialNumber2 = CST_report.map((CST_report) => {
    return {
      ...CST_report,
      SNo2: SNo2++,
    };
  });

  const rows3 = rowsWithSerialNumber2;

  const columnsCompleted: GridColumns = [

    {
      field: "SNo2",
      headerName: "S.No",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
     
    },

    {
      field: "locationdesc",
      headerName: "Location",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },

    },
    {
      field: "topicdesc",
      headerName: "Topic",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "perioddesc",
      headerName: "Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },



    },
    {
      field: "totalcount",
      headerName: "Total Completed",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },

      
    },
    {
      field: "high",
      headerName: "Priority High",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
     
    },
    {
      field: "medium",
      headerName: "Priority Medium",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
     
    },
    {
      field: "low",
      headerName: "Priority Lowest",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
  ];



  let SNo3 = 1;
  const rowsWithSerialNumber3 = OST_bk_report.map((OST_bk_report) => {
    return {
      ...OST_bk_report,
      SNo3: SNo3++,
    };
  });

  const rows4 = rowsWithSerialNumber3;



  const columnsDrillout: GridColumns = [

    {
      field: "SNo3",
      headerName: "S.No",
       minwidth: 200,
      flex: 0.5,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      className: "gridCell",
      width: 200,
      // renderCell: (params) => {
      //   // Apply custom styles to the cell element
      //   return (
      //     <div style={{ minWidth: 200, textAlign: "center" }}>
      //       {params.value}
      //     </div>
      //   );
      // },
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "number",
      minwidth: 200,
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      width: 200,
    },

    {
      field: "topicdesc",
      headerName: "Topic",
      minwidth: 200,
      // minwidth: 200,
      flex: 1.5,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      width: 200,

    },

    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      headerAlign: "center",
      minwidth: 200,
      width: 200,
    },
    {
      field: "perioddesc",
      headerName: "Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1,
      minwidth: 200,
      align: "center",
      headerAlign: "center",
      width: 200,
      //cellClassName: "custom-cell",

    },
    {
      field: "solutiondesc",
      headerName: "Solution",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 3,
      align: "center",
      headerAlign: "center",
      minwidth: 200,
      width: 200,
      //cellClassName: "custom-cell",

      // renderCell: (params) => (
      //   <Tooltip title={params.value}>
      //     <span>{params.value?.substring(0, 18)}...</span>
      //   </Tooltip>
      // ),
      renderCell: (params) => (
        <Tooltip title={params.value}>
          {/* <span>
            {params.value?.length > 50
              ? `${params.value.substring(0, 50)}...`
              : params.value}
          </span> */}
          <div
            style={{
              //maxHeight: '120px', // Set the maximum height for the cell
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
             // height: '85%',
              
            
            }}
          >
            <span>
              {params.value?.length > 50
                ? `${params.value.substring(0, 50)}...`
                : params.value}
            </span>
          </div>
        </Tooltip>
      ),
      //editable: true,
    },
    {
      field: "prioritydesc",
      headerName: "Priority",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      headerAlign: "center",
      minwidth: 200,
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },

  ];

  let SNo4 = 1;
  const rowsWithSerialNumber4 = CST_bk_report.map((CST_bk_report) => {
    return {
      ...CST_bk_report,
      SNo4: SNo4++,
    };
  });

  const rows5 = rowsWithSerialNumber4;

  const columnsCompleteddrill: GridColumns = [

    {
      field: "SNo4",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.5,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "number",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },

    {
      field: "topicdesc",
      headerName: "Topic",
      // minwidth: 200,
      flex: 1.5,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",

    },

    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "perioddesc",
      headerName: "Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1,

      align: "center",
      headerAlign: "center",


    },
    {
      field: "updated_at",
      headerName: "Completed On",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 1,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },


    },
    {
      field: "solutiondesc",
      headerName: "Solution",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 3,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Tooltip title={params.value}>
           <div
          style={{
            maxHeight: "30em", // Set max height to roughly 2-3 lines
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            //height:'85%'
          }}
        >
          {params.value?.length > 50
                ? `${params.value.substring(0, 50)}...`
                : params.value}
         {/* <span>{params.value?.substring(0, 50)}...</span> */}
        </div>
          {/* <span>{params.value?.substring(0, 18)}...</span> */}
        </Tooltip>
      ),
      //editable: true,
    },
    {
      field: "prioritydesc",
      headerName: "Priority",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },
      //editable: true,
    },

  ];




  let SNoTopReport = 0;
  const rowsWithSerialNumberTopReport = report_res.map((report_res) => {
    return {
      ...report_res,
      SNoTopReport: SNoTopReport + 1,
      id:SNoTopReport++
    };
  });

  const rowsTopReport = rowsWithSerialNumberTopReport;



  const columnsTopReport: GridColumns = [

    {
      field: "SNoTopReport",
      headerName: "S.No",
         flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "cuzdesc",
      headerName: "Topic",
         flex: 1.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >35
                ? `${params.value.substring(0, 35)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "locationcount",
      headerName: "Participating Locations",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "countrycount",
      headerName: "Participating Countries",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "testcount",
      headerName: "Quizzes Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
      {
      field: "lastcount",
      headerName: "Quizzes Last Month",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "assignedcount",
      headerName: "Assigned Users",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "highnon",
      headerName: "High Risks NOT Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "mediumnon",
      headerName: "Medium Risks NOT Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },
    {
      field: "allnon",
      headerName: "All Risks NOT Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >8
                ? `${params.value.substring(0, 8)}...`
                : params.value}
          </Tooltip>
        );
            },
    },

  ];
let rowsWithSerialNumberCompleteReport = [];
if(drill_report_data != undefined){
  let SNoCompleteReport = 0;
   rowsWithSerialNumberCompleteReport = drill_report_data.map((drill_report_data) => {
    return {
      ...drill_report_data,
      SNoCompleteReport: SNoCompleteReport + 1,
      id:SNoCompleteReport++
    };
  });
}
  // const rowsWithSerialNumberCompleteReport =  drill_report_data.map((drill_report_data) => {
  //   return {
  //     ...drill_report_data,
  //     SNoCompleteReport: SNoCompleteReport + 1,
  //     id:SNoCompleteReport++
  //   };
  // });


  let rowsCompleteReport = rowsWithSerialNumberCompleteReport;;

  const columnsCompleteReport: GridColumns = [

    {
      field: "SNoCompleteReport",
      headerName: "S.No",
         flex: 0.2,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "cuzdesc",
      headerName: "Topic",
         flex: 1.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 35
                ? `${params.value.substring(0, 35)}...`
                : params.value}
          </Tooltip>
        );
      },
    }, 
    {
      field: "locationdesc",
      headerName: "Location",
         flex: 1.5,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >35
                ? `${params.value.substring(0, 35)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >6
                ? `${params.value.substring(0, 6)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "previousperiod",
      headerName: "Previous Period",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
      {
      field: "latestperiod",
      headerName: "Latest Period",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "assignuser",
      headerName: "Assigned Users",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "hinon",
      headerName: "High Risks NOT Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "mednon",
      headerName: "Medium Risks NOT Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
    {
      field: "allrisknon",
      headerName: "All Risks NOT Completed",
         flex: 0.6,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },

  ];



  let SNo9 = 1;
  const rowsWithSerialNumberpast = PDT_report.map((PDT_report) => {
    return {
      ...PDT_report,
      SNo9: SNo9++,
      id: SNo9++,
    };
  });

  const rowspast = rowsWithSerialNumberpast;

  const columnspast: GridColumns = [

    {
      field: "SNo9",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },

    {
      field: "cuzdesc",
      headerName: "Topic",
      // minwidth: 200,
      flex: 1.3,
      // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },

    },
    {
      field: "locationdesc",
      headerName: "Location",
      // width: 200,
      // type: "number",
      flex: 1.3,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "countrycode",
      headerName: "Country",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    {
      field: "periodcode",
      headerName: "Last Period",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
     

    },
    {
      field: "aged_months",
      headerName: " Aged Months ",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.5,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >5
                ? `${params.value.substring(0, 5)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
    {
      field: "emailsend",
      headerName: "Email Last Sent",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.5,
      hide: !emailTopic,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.emailsend === "0000-00-00" ? "" : params.row.emailsend,

      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            
            {params.value?.length >10
                ? `${params.value.substring(0, 10)}...`
                : params.value}
          </Tooltip>
        );
      },
      
      //editable: true,
    },
   
  ];





  return (
    <>
      <div className="container">
        {run == "" ? (
          <>
            <div align="center">
              {/* <h4 style={{ paddingTop: "2%" }}>Completed Topics Reports</h4> */}
              <h4 style={{ paddingTop: "2%" }}>{report}</h4>
            </div>

            <div className="row text-center">
              {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5"> */}
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    history.push("/Extquestion2");
                    //console.log(value,"value");
                    // if (run == "run_report") setRun("");
                    // if (run == "drill_report") setRun("run_report");
                    // if (run == "") history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">


                <div class="btn-group drop " role="group">
                  <select
                    style={{ borderColor: "#ff8c24" }}
                    type="text"
                    defaultValue={groupid}
                    withAll={true}
                    multi={true}
                    value={reportgroup}
                    onClick={() => {
                      getReportlocation();
                      console.log("location");
                      console.log("group", descGroup);
                      console.log(reportgroup, "Reportgroup")

                    }}
                    // onClick={(e) => {
                    //   getReportlocation();

                    //  }}
                    onChange={(e) => {


                      setSelectedOptions("true");
                      setReportgroup(e.target.value);
                      console.log("value", e.target.value);
                    }}
                  >
                    <option value={descGroup.custgroupid}>All</option>
                    {descGroup?.map((data, i) => {

                      //   console.log(country)
                      return (
                        <option value={data.custgroupid}>
                          {data.custgroupdesc?.substring(0, 26)}
                        </option>
                      );
                    })}
                  </select>
                  <button class="btn btn-default ant-btn-default1" type="button" tabindex="-1"
                    onClick={() => {
                      getgrouplocation();
                      console.log("Add Locations");
                    }}
                  >Run</button>

                </div>

              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div class="btn-group drop " role="group">
                  <select className="report-select"
                    style={{ borderColor: "#ff8c24" }}
                    type="text"
                    value={report}

                    onChange={(e) => {

                      setReport(e.target.value);
                      console.log("value", e.target.value);
                    }}

                  >
                    <option value="Completed Topics">Completed Topics</option>
                    <option value="Past Due Topics">Past Due Topics</option>
                    <option value="Assigned Tasks">Assigned Tasks</option>
                    <option value="Outstanding Tasks">Outstanding Tasks</option>
                    <option value="Completed Tasks">Completed Tasks</option>
                  </select>
                </div>
              </div>


              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    if (report == "Assigned Tasks") {
                      assignedTasks_report_api();
                      setCsv_table_type("assigned_tasks_topics");
                    } else if (report == "Completed Topics") {

                      run_report_api();
                      setCsv_table_type("run_report");

                    } else if (report == "Past Due Topics") {

                      pastDueTopics_report_api();
                      setCsv_table_type("past_due_topics");

                    } else if (report == "Outstanding Tasks") {

                      outstanding_tasks_topics_report();
                      setCsv_table_type("Outstanding Tasks");

                    }
                    else if (report == "Completed Tasks") {

                      completed_tasks_topics_report();
                      setCsv_table_type("Completed Tasks");

                    }
                  }}
                >
                  Run Report
                </StyledButton>
              </div>



              {report === 'Completed Tasks' && (
                <>
                  <div  className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                    <div style={{textAlign:"center"}}>
                    <label >Report Start Date:</label>
                    <DatePicker
                    customInput={<input className="my-custom-datepicker" />}
                      selected={reportStartDate}
                      onChange={date => setReportStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      maxDate={reportEndDate}
                      className="custom-datepicker"
                    />
                  </div>
                  </div>

                  <div  className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5">
                    <label>Report End Date:</label>
                    <DatePicker
                    customInput={<input className="my-custom-datepicker" />}
                      selected={reportEndDate}
                      onChange={date => setReportEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      minDate={reportStartDate}
                      maxDate={new Date()}
                    />
                  </div>
                  </>
              )}


              {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                {!run ? (
                  <StyledButton className="row1-btn"
                    onClick={() => {
                      run_report_api();
                      setCsv_table_type("run_report");
                    }}
                  >
                    Completed Topics
                  </StyledButton>
                ) : (
                  <StyledButton
                    onClick={() => {
                      drill_report_api();
                      setCsv_table_type("drill_report");
                    }}
                  >
                    Drill Down
                  </StyledButton>
                )}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    pastDueTopics_report_api();
                    setCsv_table_type("past_due_topics");
                  }}
                >
                  Past Due Topics
                </StyledButton>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    assignedTasks_report_api();
                    setCsv_table_type("assigned_tasks_topics");
                  }}
                >
                  Assigned Tasks
                </StyledButton></div>*/}

            </div>



            <div className="row inline-flex">
              {/*<-----------Country Table-----------> */}
              <div className="col-sm-12 col-md-4">
                <div className="mt-5">
                  {/* <h4>Country</h4> */}
                  <StyledTable class="table table-bordered">
                    <thead>
                      <StyledTableHeader>
                        <StyledTableHeaderData>S.No</StyledTableHeaderData>
                        <StyledTableHeaderData>Country</StyledTableHeaderData>
                        <StyledTableHeaderData>
                          <input
                            type="checkbox"
                            className="checkbox select-all1 form-check-input"
                            onClick={(e) => {
                              {
                                countryList &&
                                  countryList?.map((data, index) => {
                                    if (
                                      !country_data.includes(data.countrycode)
                                    ) {
                                      if (e.target.checked == true) {
                                        country_data.push(data);
                                        country_data_json.push({
                                          countrycode: data.countrycode,
                                        });

                                        console.log(
                                          "contry m data arrray===>",
                                          country_data,
                                          country_data_json
                                        );
                                      } else {
                                        country_data.pop(data);
                                        country_data_json.pop({
                                          countrycode: data.countrycode,
                                        });

                                        console.log(
                                          "contry m pop data arrray===>",
                                          country_data,
                                          country_data_json
                                        );
                                      }
                                    }
                                  });
                              }
                            }}
                          />
                        </StyledTableHeaderData>
                      </StyledTableHeader>
                    </thead>

                    <tbody>
                      {countryList &&
                        countryList?.map((data, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.countrycode}</td>
                                <td>
                                  <input
                                    name="check[1]"
                                    key={index}
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={data.isChecked}
                                    onChange={(e) => {
                                      if (
                                        !country_data.includes(data.countrycode)
                                      ) {
                                        if (e.target.checked == true) {
                                          country_data.push(data);
                                          country_data_json.push({
                                            countrycode: data.countrycode,
                                          });

                                          console.log(
                                            "contry data arrray===>",
                                            country_data,
                                            country_data_json
                                          );
                                        } else {
                                          country_data.pop(data);
                                          country_data_json.pop({
                                            countrycode: data.countrycode,
                                          });

                                          console.log(
                                            "contry pop data arrray===>",
                                            country_data,
                                            country_data_json
                                          );
                                        }
                                      }
                                    }}
                                    id={index}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </StyledTable>
                </div>
              </div>
              {/*<-----------Location Table-----------> */}
              <div className="col-sm-12 col-md-4">
                <div className="mt-5">
                  {/* <h4>Location</h4> */}
                  <StyledTable class="table-auto">
                    <thead>
                      <StyledTableHeader>
                        {" "}
                        <StyledTableHeaderData>
                          S.No &nbsp;
                        </StyledTableHeaderData>
                        <StyledTableHeaderData style={{ paddingLeft: "4%" }}>
                          Location &nbsp;
                        </StyledTableHeaderData>
                        <StyledTableHeaderData>
                          <input
                            name="allChecked"
                            type="checkbox"
                            className="checkbox select-all2 form-check-input"
                            onClick={(e) => {
                              {
                                locationList &&
                                  locationList?.map((data, index) => {
                                    {
                                      if (!loc_data.includes(data.locationid)) {
                                        if (e.target.checked == true) {
                                          loc_data.push(data);
                                          loc_data_json.push({
                                            custgroupid: data.custgroupid,
                                            locationid: data.locationid,
                                            locationdesc: data.locationdesc,
                                          });
                                          console.log(
                                            "location m data arrray===>",
                                            loc_data,
                                            loc_data_json
                                          );
                                        } else {
                                          loc_data.pop(data);
                                          loc_data_json.pop({
                                            custgroupid: data.custgroupid,
                                            locationid: data.locationid,
                                            locationdesc: data.locationdesc,
                                          });
                                          console.log(
                                            "location pop m data arrray===>",
                                            loc_data,
                                            loc_data_json
                                          );
                                        }
                                      }
                                    }
                                  });
                              }
                            }}
                          />
                        </StyledTableHeaderData>
                      </StyledTableHeader>
                    </thead>

                    <tbody>
                      {locationList &&
                        locationList?.map((data, index) => {
                          return (
                            <>
                              <tr key={data.locationid}>
                                <td>{index + 1}</td>
                                <td id={data.locationid}>
                                  {data.locationdesc}
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="check[2]"
                                    // checked={selectAllLocationList}
                                    onChange={(e) => {
                                      if (!loc_data.includes(data.locationid)) {
                                        if (e.target.checked == true) {
                                          loc_data.push(data);
                                          loc_data_json.push({
                                            custgroupid: data.custgroupid,
                                            locationid: data.locationid,
                                            locationdesc: data.locationdesc,
                                          });
                                          console.log(
                                            "location data arrray===>",
                                            loc_data,
                                            loc_data_json
                                          );
                                        } else {
                                          loc_data.pop(data);
                                          loc_data_json.pop({
                                            custgroupid: data.custgroupid,
                                            locationid: data.locationid,
                                            locationdesc: data.locationdesc,
                                          });
                                          console.log(
                                            "location pop data arrray===>",
                                            loc_data,
                                            loc_data_json
                                          );
                                        }
                                      }
                                    }}
                                    id={data.locationid}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </StyledTable>
                </div>{" "}
              </div>{" "}
              {/*<-----------Topic Table-----------> */}
              <div className="col-sm-12 col-md-4">
                <div className="mt-5">
                  {/* <h4>Topic</h4> */}
                  <StyledTable class="table-auto">
                    <thead>
                      <StyledTableHeader>
                        {" "}
                        <StyledTableHeaderData>
                          S.No &nbsp;
                        </StyledTableHeaderData>
                        <StyledTableHeaderData style={{ paddingLeft: "4%" }}>
                          Topic &nbsp;
                        </StyledTableHeaderData>
                        <StyledTableHeaderData>
                          <input
                            type="checkbox"
                            className="checkbox select-all3 form-check-input"
                            onChange={(e) => {
                              {
                                topicList &&
                                  topicList?.map((data, index) => {
                                    if (!topic_data.includes(data.topicid))
                                      if (e.target.checked == true) {
                                        topic_data.push(data);
                                        topic_data_json.push({
                                          topicid: data.topicid,
                                          cuzdesc: data.cuzdesc,
                                          cuztopic: data.cuztopic,
                                          topiccodes: data.topiccodes,
                                        });
                                        console.log(
                                          "topic m data arrray===>",
                                          topic_data,
                                          topic_data_json
                                        );
                                      } else {
                                        topic_data.pop(data);
                                        topic_data_json.pop({
                                          topicid: data.topicid,
                                          cuzdesc: data.cuzdesc,
                                          cuztopic: data.cuztopic,
                                          topiccodes: data.topiccodes,
                                        });
                                        console.log(
                                          "topic pop m data arrray===>",
                                          topic_data,
                                          topic_data_json
                                        );
                                      }
                                  });
                              }
                            }}
                          />
                        </StyledTableHeaderData>
                      </StyledTableHeader>
                    </thead>

                    <tbody>
                      {topicList &&
                        topicList?.map((data, index) => {
                          return (
                            <>
                              <tr key={data.topicid}>
                                <td>{index + 1}</td>
                                <td id={data.topicid}>{data.cuzdesc}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="check[3]"
                                    //  checked={selectAllTopicList}
                                    onChange={(e) => {
                                      if (!topic_data.includes(data.topicid))
                                        if (e.target.checked == true) {
                                          topic_data.push(data);
                                          topic_data_json.push({
                                            topicid: data.topicid,
                                            cuzdesc: data.cuzdesc,
                                            cuztopic: data.cuztopic,
                                            topiccodes: data.topiccodes,
                                          });
                                          console.log(
                                            "topic data arrray===>",
                                            topic_data_json
                                          );
                                        } else {
                                          topic_data.pop(data);
                                          topic_data_json.pop({
                                            topicid: data.topicid,
                                            cuzdesc: data.cuzdesc,
                                            cuztopic: data.cuztopic,
                                            topiccodes: data.topiccodes,
                                          });
                                          console.log(
                                            "topic pop data arrray===>",
                                            topic_data,
                                            topic_data_json
                                          );
                                        }
                                    }}
                                    id={data.topicid}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </StyledTable>
                </div>{" "}
              </div>
            </div>
          </>
        ) : run == "run_report" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Completed Topics Report</h4>
            </div>

            <div className=" row row1" align="center">
              {/* col-sm-12 col-md-6 col-lg-6 col-xl-2 */}
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");

                    // if (run == "") history.push("/Extquestion2");
                    // if (run == "run_report") setRun("");
                    // if (run == "drill_report") setRun("run_report");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    setRun("");
                    // if (run == "") history.push("/Extquestion2");
                    // if (run == "run_report") setRun("");
                    // if (run == "drill_report") setRun("run_report");
                  }}
                >
                  New Selection

                </StyledButton>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                {!run ? (
                  <StyledButton
                    onClick={() => {
                      run_report_api();
                      //  setRun(true)
                    }}
                  >
                    Run Report
                  </StyledButton>
                ) : (
                  <StyledButton className="row1-btn"
                    onClick={() => {
                      //  setRun(true)
                      drill_report_api();
                    }}
                  >
                    Drill Down
                  </StyledButton>
                )}
              </div>

              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_run_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn" onClick={() => print_runReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>

            {/* <div className="mt-5 table-responsive tableFixHeadLarge">
              <StyledTable className="table-bordered">
                <thead>
                  <ScrollTableHeader>
                    <StyledTableHeaderData>S.No</StyledTableHeaderData>
                    <StyledTableHeaderData>Topic</StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Participating Locations
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Participating Countries
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Quizzes Completed
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Quizzes Last Month
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Assigned Users
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      High Risks NOT Completed
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Medium Risks NOT Completed
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      All Risks NOT Completed
                    </StyledTableHeaderData>
                  </ScrollTableHeader>
                </thead>

                <tbody>
                  {report_res &&
                    report_res?.map((data, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>{data.cuzdesc}</td>
                            <td>{data.locationcount}</td>
                            <td>{data.countrycount}</td>
                            <td>{data.testcount}</td>
                            <td>{data.lastcount}</td>
                            <td>{data.assignedcount}</td>
                            <td>
                              {data.highnon} of {data.hightotal}
                            </td>
                            <td>
                              {data.mediumnon} of {data.mediumtotal}
                            </td>
                            <td>
                              {data.allnon} of {data.alltotal}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </StyledTable>
            </div> */}



<div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rowsTopReport}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsTopReport}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                hideFooterPagination={rowsTopReport.length <= pageSize}
                pagination
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>




          </>
        ) : run == "drill_report" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>
                Completed Topic Details Report
              </h4>
            </div>

            <div className="row" align="center">

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");

                    // if (run == "") history.push("/Extquestion2");
                    // if (run == "run_report") setRun("");
                    // if (run == "drill_report") setRun("run_report");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    setRun("run_report");
                    // if (run == "") history.push("/Extquestion2");
                    // if (run == "run_report") setRun("");
                    // if (run == "drill_report") setRun("run_report");
                  }}
                >
                  Summary Report

                </StyledButton>
              </div>

              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                          <StyledButton
                            onClick={() => download_csv_drill_report()}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                          <StyledButton onClick={() => print_drillReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>

            {/* <div className="mt-5 table-responsive tableFixHeadLarge">
              <StyledTable className="table-bordered">
                <thead>
                  <ScrollTableHeader>
                    <StyledTableHeaderData>
                      S.No{<br />}
                      <a
                        onClick={() => {
                          setSortRev(!sortRev);
                          setSortByTopic(false);
                          setSortByLocation(false);
                          setSortByCountry(false);
                          setSortByPreviousPeriod(false);
                          setSortByLatestPeriod(false);
                          setSortBySno(true);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Topic{<br />}
                      <a
                        onClick={() => {
                          setSortRev(!sortRev);
                          setSortByLocation(false);
                          setSortByCountry(false);
                          setSortByPreviousPeriod(false);
                          setSortByLatestPeriod(false);
                          setSortBySno(false);
                          setSortByTopic(true);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Location{<br />}
                      <a
                        onClick={() => {
                          setSortRev(!sortRev);
                          setSortByTopic(false);
                          setSortByCountry(false);
                          setSortByPreviousPeriod(false);
                          setSortByLatestPeriod(false);
                          setSortBySno(false);
                          setSortByLocation(true);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Country{<br />}
                      <a
                        onClick={() => {
                          setSortRev(!sortRev);
                          setSortByTopic(false);
                          setSortByLocation(false);
                          setSortByPreviousPeriod(false);
                          setSortByLatestPeriod(false);
                          setSortBySno(false);
                          setSortByCountry(true);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Previous{<br />}Period{" "}
                      <a
                        onClick={() => {
                          setSortRev(!sortRev);
                          setSortByTopic(false);
                          setSortByLocation(false);
                          setSortByCountry(false);
                          setSortByLatestPeriod(false);
                          setSortBySno(false);
                          setSortByPreviousPeriod(true);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Latest{<br />}Period{" "}
                      <a
                        onClick={() => {
                          setSortRev(!sortRev);
                          setSortByTopic(false);
                          setSortByLocation(false);
                          setSortByCountry(false);
                          setSortByPreviousPeriod(false);
                          setSortBySno(false);
                          setSortByLatestPeriod(true);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Assigned{<br />}Users
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      High Risks{<br />}
                      NOT Completed
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Medium Risks{<br />}
                      NOT Completed
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      All Risks{<br />}
                      NOT Completed
                    </StyledTableHeaderData>
                  </ScrollTableHeader>
                </thead>

                <tbody>
                  {drill_report_data &&
                    drill_report_data?.map((data, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>{data.cuzdesc}</td>
                            <td>{data.locationdesc}</td>
                            <td>{data.countrycode}</td>
                            <td>{data.previousperiod}</td>
                            <td>{data.latestperiod}</td>
                            <td>{data.assignuser}</td>
                            <td>
                              {data.hinon} of {data.hitotal}
                            </td>
                            <td>
                              {data.mednon} of {data.medtotal}
                            </td>
                            <td>
                              {data.allrisknon} of {data.allrisktotal}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </StyledTable>
            </div> */}


<div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
<DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rowsCompleteReport}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsCompleteReport}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                hideFooterPagination={rowsCompleteReport.length <= pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />
              
</div>



          </>
        ) : run == "past_due_topics" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Past Due Topics Report</h4>
            </div>

            <div className="row buttonhide" align="center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    setRun("");
                  }}
                >
                  New Selection
                </StyledButton>
              </div>

              <div hidden={!emailTopic}
                className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">

                <StyledButton className="row1-btn"

                  onClick={() => {
                    past_due_topics_email_report_api();
                  }}>

                  Send Email
                </StyledButton>
              </div>

              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_PDT_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn" onClick={() => print_PDTReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>



            <div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rowspast}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnspast}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                hideFooterPagination={rowspast.length <= pageSize}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>



            {/* <div className="mt-5 table-responsive tableFixHeadLarge">
              <StyledTable className="table-bordered">
                <thead>
                  <ScrollTableHeader>
                    <StyledTableHeaderData>
                      S.No&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('sno', PDT_report)
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Topic&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('cuzdesc', PDT_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Location&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('locationdesc', PDT_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Country&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('countrycode', PDT_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Last Period&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('periodcode', PDT_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Aged Months &nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('aged_months', PDT_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData
                      hidden={!emailTopic}>
                      Email Last Sent &nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          onSort('emailsent', PDT_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                  </ScrollTableHeader>
                </thead>

                <tbody>
                  {PDT_report &&
                    PDT_report?.map((data, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.cuzdesc}</td>
                            <td>{data.locationdesc}</td>
                            <td>{data.countrycode}</td>
                            <td>{data.periodcode}</td>
                            <td>{data.aged_months}</td>
                            <td hidden={!emailTopic}>{data.emailsend == '0000-00-00' ? '' : data.emailsend}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </StyledTable>
            </div> */}
          </>
        ) : run == "assigned_tasks_topics" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Assigned Tasks Report</h4>
            </div>

            <div className="row row1" align="center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className={"col-sm-12 col-md-6 col-lg-6 col-xl-1-5"}>
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    setRun("");
                  }}
                >
                  New Selection
                </StyledButton>
              </div>

              {AST_report_emailsugg['emailsugg'] == "Y" ?
                (
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                    <StyledButton className="row1-btn"
                      onClick={() => {
                        Assigned_tasks_email_api();
                      }}
                    >
                      Send Email
                    </StyledButton>
                  </div>
                ) : (<></>)
              }



              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_AST_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn" onClick={() => print_ASTReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>




            <div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rows1}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsAssign}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                hideFooterPagination={rows1.length <= pageSize}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>



            {/* <div className="mt-5 table-responsive tableFixHeadLarge">
              <StyledTable className="table-bordered">
                <thead>
                  <ScrollTableHeader>
                    <StyledTableHeaderData>
                      S.No&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('sno', AST_bk_report)
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Topic&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('topicdesc', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Location&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('locationdesc', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Country&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('countrycode', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Assigned To&nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('qownerid', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Solution &nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('solutiondesc', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Priority &nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('prioritydesc', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Due Date &nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('qduedate', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                    <StyledTableHeaderData>
                      Days Past Due &nbsp;
                      <a
                        onClick={() => {
                          setSortType(!sortType);
                          ASTsort('pastdue', AST_bk_report);
                        }}
                      >
                        <i class="fas fa-sort" />
                      </a>
                    </StyledTableHeaderData>
                  </ScrollTableHeader>
                </thead>

                <tbody>
                  {AST_report &&
                    AST_report?.map((data, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{data.topicdesc}</td>
                            <td>{data.locationdesc}</td>
                            <td>{data.countrycode}</td>
                            <td><Tooltip title={data.qownerid}>
                              <span>
                                {data.qownerid?.substring(0, 10)}...
                              </span>
                            </Tooltip></td>
                            <td>

                              <Tooltip title={data.solutiondesc}>
                                <span>
                                  {data.solutiondesc?.substring(0, 25)}...
                                  {/*{data.solutiondesc?.substring(0, 25)}*/}
            {/* </span>
                              </Tooltip></td>
                            <td>{data.prioritydesc}</td>
                            <td>{data.qduedate}</td>
                            <td>{data.pastdue}</td>


                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </StyledTable>
            </div>  */}


          





          </>
        ) : run == "outstanding_tasks_topics" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Outstanding Tasks Summary Report</h4>
            </div>

            <div className="row row1" align="center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className={"col-sm-12 col-md-6 col-lg-6 col-xl-1-5"}>
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    setRun("");
                  }}
                >
                  New Selection
                </StyledButton>
              </div>


              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setRun("outstanding_tasks_details");
                    setCsv_table_type("Outstanding Tasks details");
                  }}
                >
                  Drill Down
                </StyledButton>
              </div>




              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_OST_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn" onClick={() => print_OSTReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>


            <div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rows2}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsOutstanding}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                hideFooterPagination={rows2.length <= pageSize}
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>





          </>
        ) : run == "outstanding_tasks_details" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Outstanding Tasks Details Report</h4>
            </div>

            <div className="row row1" align="center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>



              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setRun("outstanding_tasks_topics");
                  }}
                >
                  Summary Report
                </StyledButton>
              </div>




              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_OST_drill_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                          <StyledButton className="row1-btn" onClick={() => print_OST_detailsReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>


            <div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rows4}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsDrillout}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                hideFooterPagination={rows4.length <= pageSize}
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>





          </>
        ) : run == "completed_tasks_topics" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Completed Tasks Summary Report</h4>
            </div>

            <div className="row row1" align="center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>
              <div className={"col-sm-12 col-md-6 col-lg-6 col-xl-1-5"}>
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    setRun("");
                  }}
                >
                  New Selection
                </StyledButton>
              </div>


              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setRun("completed_tasks_details");
                    setCsv_table_type("Completed Tasks details");
                  }}
                >
                  Drill Down
                </StyledButton>
              </div>




              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_CST_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-1-5">
                          <StyledButton className="row1-btn" onClick={() => print_CSTReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>


            <div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rows3}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsCompleted}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                hideFooterPagination={rows3.length <= pageSize}
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>





          </>
        ) : run == "completed_tasks_details" ? (
          <>
            <div align="center">
              <h4 style={{ paddingTop: "2%" }}>Completed Tasks Details Report</h4>
            </div>

            <div className="row row1" align="center">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setCountry_data_json([]);
                    setCountry_data([]);
                    setLoc_data_json([]);
                    setLoc_data([]);
                    setTopic_data_json([]);
                    setTopic_data([]);
                    history.push("/Extquestion2");
                  }}
                >
                  Exit
                </StyledButton>
              </div>



              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <StyledButton className="row1-btn"
                  onClick={() => {
                    setRun("completed_tasks_topics");
                  }}
                >
                  Summary Report
                </StyledButton>
              </div>




              {list &&
                list?.map((data, index) => {
                  return (
                    <>
                      {list[index] &&
                        list[index].listvalue == "Download Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                          <StyledButton className="row1-btn"
                            onClick={() => {
                              download_csv_CST_drill_report();
                            }}
                          >
                            Download Report &nbsp;
                            <DownloadOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {list[index] &&
                        list[index].listvalue == "Print Reports" ? (
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                          <StyledButton className="row1-btn" onClick={() => print_CST_detailsReport()}>
                            Print Report &nbsp;
                            <PrinterOutlined style={{ fontSize: 20 }} />
                          </StyledButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>


            <div
              style={{ height: '70vh', width: "100%", marginTop: 30 }}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rows5}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsCompleteddrill}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                pagination
                hideFooterPagination={rows5.length <= pageSize}
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>





          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Ecompleted_report;
