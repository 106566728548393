import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { DataGrid, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { message } from "antd";
import { Tooltip } from "antd";
import { makeStyles } from "@material-ui/styles";
import {
  add_new_custom_topic,
  receive_custom_topic_data,
  receive_custom_topic_status,
  receive_status_info,
  update_custom_topic_data,
  update_custom_topic_status,
} from "../../../services/AdminProgramApi";
import { check_status_massage } from "../../../services/CuzDriveApi";
const theme = localStorage.getItem('brandColor');
const StyledForm = styled.form`
  display: flex;
  justify-content: center;
  //   margin-left: 20%;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  flex: 1;
  margin-right: 10px;
  display: inline;
  width: 30%;
`;

const StyledFormInputDiv = styled.div`
  display: flex;

  margin-top: 4px;

  width: 100%;
`;

const FormDivStyle = {
  position: "relative",
  display: "inline",
  width: "100%",
  textAlign: "left",
};

const StyledButton = styled.button`
  background: ${theme};
  width: 100%;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 25px;
  padding: 5px 10px;
`;

const StyledButtonCB = styled.button`
  background: ${theme};
  width: 300px;
  border-radius: 30px;
  height: 45px;
  text-align: center;
  color: white;
  font-size: 16px !important;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 20px;
  padding: 5px 10px;
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
`;

const ScrollTableHeader = styled.tr`
  background: ${theme};
  font-weight: bold;
  color: #ffffff;
  font-family: Nunito;
  font-style: normal;
  position: sticky;
  top: 0;
`;
const StyledTableRow = styled.tr`
  border-bottom: 1px solid #9d9d9d;
`;

const StyledTableHeaderData = styled.th`
  font-weight: bold;
  padding 5px;
  font-family: Nunito;
  font-style: normal;
`;

const StyledButton1 = styled.button`
  background: ${theme};
  width: 250px;
  border-radius: 20px;
  height: 35px;
  text-align: center;
  font-size: 18px !important;

  color: white;
  font-weight: 500;
  border-color: none;
  border: none;
  margin-top: 15px;
  padding: 5px 10px;
`;

const StyledButtonST = styled.button`
  background: ${theme};
  width: 80px;
  border-radius: 20px;
  height: 25px;
  text-align: center;
  font-size: 15px !important;
  color: white;
  font-weight: 400;
  border-color: none;
  border: none;
`;

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--cell": {
      backgroundColor: "rgba(224, 183, 60, 0.55)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.negative": {
      backgroundColor: "rgba(157, 255, 118, 0.49)",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app.positive": {
      // backgroundColor: '#d47483',
      border: "1px solid #ff8c24",
      color: "#1a3e72",
      fontWeight: "600",
    },
    "& .super-app-theme--header": {
      backgroundColor: "#ff8c24",
      border: "1px solid #ff8c24",
      color: "#FFFFFF",
      fontWeight: "600",
    },

    
  },
});





const Custom_Topic_Data = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  console.log("propdata", match);

  const [edit_custom_topic_data_modal, setEdit_custom_topic_data_modal] =
    useState(false);
  const [add_custom_topic_data_modal, setAdd_custom_topic_data_modal] =
    useState(false);
  const [custom_topic_status_modal, setCustom_topic_status_modal] =
    useState(false);
  const [status_info_modal, setStatus_info_modal] = useState(false);
  const [cb_Modal, setCb_Modal] = useState(false);
  const [customTopicTitle, setCustomTopicTitle] = useState("");
  const [customTopicSummary, setCustomTopicSummary] = useState("");
  const [topicId, setTopicId] = useState("");
  const [topicStatus, setTopicStatus] = useState("");
  const [statusEditPermission, setStatusEditPermission] = useState("");

  const [status_Masaage, set_Status_Message] = useState([]);
  const [getTopicid, setGetTopicid] = useState([]);

  const [customTopicData, setCustomTopicData] = useState([]);
  const [customTopicStatus, setCustomTopicStatus] = useState([]);
  const [infoStatus, setInfoStatus] = useState([]);

  const [formErrors, setFormErrors] = useState([]);

  const [pageSize, setPageSize] = useState(100);

  const [userid, setUserid] = useState(localStorage.getItem("UserID"));
  const customerid = match.params.customerid;

  // ----------------------- Validation -------------------------
  const handleValidation = () => {
    let formErrors = {};
    let formIsValid = true;
    if (!customTopicTitle) {
      formIsValid = false;
      formErrors["customTopicTitle_error"] = "Custom Topic Title is required.";
    }
    if (!customTopicSummary) {
      formIsValid = false;
      formErrors["customTopicSummary_error"] =
        "Custom Topic Summary is required.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  // ------- Reset Fields Function --------
  const resetFields = () => {
    setCustomTopicTitle("");
    setCustomTopicSummary("");
    setFormErrors([]);
  };

  // --------------------- Recieve Table Data API ----------------------

  const ReceiveCustomTopicData = async () => {
    receive_custom_topic_data({
      customerid: customerid,
    })
      .then((res) => {
        console.log(res);

        if (res.data.code === 200) {
          setCustomTopicData(res.data.Cuztoplist);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // --------------------- Recieve Status API ----------------------

  const ReceiveCustomTopicStatus = async () => {
    receive_custom_topic_status()
      .then((res) => {
        console.log(res);

        if (res.data.code === 200) {
          setCustomTopicStatus(res.data.Ctopicstatus);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);

        message.error("something went wrong");
      });
  };

  // --------------------- Recieve Status API ----------------------

  const ReceiveStatusInfo = async () => {
    receive_status_info()
      .then((res) => {
        console.log(res);

        if (res.data.code === 200) {
          setInfoStatus(res.data.data);
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);

        message.error("something went wrong");
      });
  };

  useEffect(() => {
    ReceiveCustomTopicData();
    ReceiveCustomTopicStatus();
  }, []);

  // --------------------- Update Table Data API ----------------------

  const UpdateCustomTopicData = () => {
    update_custom_topic_data({
      topicid: topicId,
      cuzdesc: customTopicTitle,
      cuzsummary: customTopicSummary,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setEdit_custom_topic_data_modal(false);
          message.success("Updated Successfully");
          ReceiveCustomTopicData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Something went wrong");
      });
  };

  // --------------------- Update Status API ----------------------

  const UpdateCustomTopicStatus = () => {
    update_custom_topic_status({
      topicid: topicId,
      tstatusid: topicStatus,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setCustom_topic_status_modal(false);
          resetFields();
          message.success("Updated Successfully");
          ReceiveCustomTopicData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Something went wrong");
      });
  };

  // --------------------- Add Topic API ----------------------

  const AddNewCustomTopic = () => {
    add_new_custom_topic({
      customerid: customerid,
      userid: userid,
      cuzdesc: customTopicTitle,
      cuzsummary: customTopicSummary,
    })
      .then((res) => {
        if (res.data.code === 200) {
          setAdd_custom_topic_data_modal(false);
          resetFields();
          message.success("Added Successfully");
          ReceiveCustomTopicData();
        } else {
          message.error("Something went wrong");
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Something went wrong");
      });
  };

  // -------------------- Continue & Begin Test ---------------------
  const show_CB_Modal = (result) => {
    setCb_Modal(true);
    setGetTopicid(result.topicid);
    check_status_massage({ topicid: result.topicid })
      .then((res) => {
        console.log("check status mesageddata===>", res);
        set_Status_Message(res.data.status_message);
      })
      .catch((err) => {
        console.log(err);
      });
  };




  const EditAdminUserButton = ({
    data,
    setCustomTopicTitle,
    setCustomTopicSummary,
    setTopicId,
    setEdit_custom_topic_data_modal,
    
  }) => {
    const handleEditClick = () => {
      setCustomTopicTitle(data.cuzdesc);
      setCustomTopicSummary(data.cuzsummary);
      setTopicId(data.topicid);
      setEdit_custom_topic_data_modal(true);
      console.log("========", statusEditPermission);
    };
  
    return (
      <a onClick={handleEditClick}>
        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };
  
  const InfoAdminUserButton = ({ data, setStatus_info_modal }) => {
    const handleInfoClick = () => {
      setStatus_info_modal(true);
      ReceiveStatusInfo();
    };
  
    return (
      <a onClick={handleInfoClick}>
        <i class="fas fa-info-circle"
                        style={{ marginLeft: 5 }}
                      ></i>
      </a>
    );
  };



  const StartButton = ({ data  }) => {
    const handleStartClick = () => {
      history.push(`/ExtQuestion/${data.topicid}`);
    };

    return (
      <StyledButtonST onClick={handleStartClick} style={{ width: 100 }}>
        Start
      </StyledButtonST>
    );
  };



  const TestButton = ({ data  }) => {
    const handleTestClick = () => {
      show_CB_Modal(data);
    };

    return (
      <StyledButtonST onClick={handleTestClick} style={{ width: 100 }}>
        Test
      </StyledButtonST>
    );
  };


  const EditStatusButton = ({
    data,
    setTopicStatus,
    setTopicId,
    setCustom_topic_status_modal,
    
  }) => {
    const handleEditstatusClick = () => {
      setTopicId(data.topicid);
      setTopicStatus(data.tstatusid);
      setCustom_topic_status_modal(true);
      console.log("status set ====>", topicStatus, topicId);
    };
  
    return (
      
      <a onClick={handleEditstatusClick}>
        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
      </a>
    );
  };



  const EditStatus = ({
    data
    
  }) => {
   
  
    return (
      
      data.tstatusid
    );
  };









  let SNo = 1;
  const rowsWithSerialNumber = customTopicData.map((customTopicData) => {
    return {
      ...customTopicData,
      SNo: SNo++,
      id:SNo
    };
  });


  const rows1  = rowsWithSerialNumber;
  const columnsUsers: GridColumns = [

    {
      field: "SNo",
      headerName: "S.No",
      // minwidth: 200,
      flex: 0.2,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      // renderCell: (params) => (
      //   <div>{params.value + 1}</div>
      // ),
    },
    
    {
      field: "cuzdesc",
      headerName: "Custom Topic Title",
      // minwidth: 200,
      flex: 1,
     // type: "email",
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
      
    },
    {
      field: "cuzsummary",
      headerName: "Custom Topic Summary",
      // width: 200,
     // type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Tooltip
           
            title={params.value}
          >
            {/* {params.value?.slice(0, 12)}... */}
            {params.value?.length > 30
                ? `${params.value.substring(0, 30)}...`
                : params.value}
          </Tooltip>
        );
      },
    },
    
    {
      field: "tstatusid",
      headerName: "Status",
      type: "text",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.4,

      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          {params.row.tstatusid}
          &nbsp; &nbsp;
          <EditStatusButton
            data={params.row}
            setTopicStatus={setTopicStatus}
            setCustomTopicSummary={setCustomTopicSummary}
            setTopicId={setTopicId}
            setCustom_topic_status_modal={setCustom_topic_status_modal}
           
            
          />
          
        </div>
      ),
    
    },
    {
      field: "Action",
      headerName: "Action",
      type: "text",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <EditAdminUserButton
            data={params.row}
            setCustomTopicTitle={setCustomTopicTitle}
            setCustomTopicSummary={setCustomTopicSummary}
            setTopicId={setTopicId}
            setEdit_custom_topic_data_modal={setEdit_custom_topic_data_modal}
            
          />
          &nbsp; &nbsp;
          <InfoAdminUserButton
            data={params.row}
           
            setStatus_info_modal={setStatus_info_modal}
          />
        </div>
      ),
    
    },
   
    {
      field: "Start/Test",
      headerName: "Start/Test",
      flex: 0.6,
      align: "center",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      renderCell: (params) => (
        <div>
          <StartButton
            data={params.row}
            
          />
          &nbsp; &nbsp;

          <TestButton
            data={params.row}
            
          />
          
        </div>
      ),


     
    },
  ];





  return (
    <div className="container-fluid">
      <div className="mt-4 row text-center" align="center">
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              history.push("/adminfunctionmodal");
            }}
          >
            Return to Admin Menu
          </StyledButton1>
        </div>
        <div className="col-6 Width100">
          <StyledButton1
            onClick={() => {
              setAdd_custom_topic_data_modal(true);
            }}
          >
            Add Custom Topic
          </StyledButton1>
        </div>
      </div>

      <div>
        {/* --------------- Edit Modal ------------ */}
        <ReactModal className="ModalStyle2 Width90" isOpen={edit_custom_topic_data_modal}>
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Edit Custom Topic</h4>
              </div>
              <StyledFormInputDiv>
                <div style={{ width: "50%" }}>
                  <StyledLabel htmlFor="qdesc">Custom Topic Title</StyledLabel>
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      //  marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Custom Topic Title"
                    value={customTopicTitle}
                    maxLength={50}
                    onChange={(e) => {
                      setCustomTopicTitle(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.customTopicTitle_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.customTopicTitle_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={{ width: "50%" }}>
                  <StyledLabel htmlFor="qdesc">
                    Custom Topic Summary
                  </StyledLabel>
                </div>
                <div style={{ width: "100%" }}>
                  <textarea
                    type="text"
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Custom Topic Summary"
                    value={customTopicSummary}
                    maxLength={300}
                    onChange={(e) => {
                      setCustomTopicSummary(e.target.value);
                      // setNumLength(e.target.value.length)
                      console.log(e.target.value);
                    }}
                    // onKeyPress={numLength === 250 ? message.error("Max characters limit is 300"): ""}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.customTopicSummary_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginTop: "0px",
                    }}
                  >
                    {formErrors.customTopicSummary_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  UpdateCustomTopicData();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setEdit_custom_topic_data_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>
      </div>

      <div>
        {/* --------------- Add Modal ------------ */}
        <ReactModal className="ModalStyle2 Width90" isOpen={add_custom_topic_data_modal}>
          <div>
            {" "}
            <StyledForm>
              <div align="center" className="mb-3">
                <h4>Add Custom Topic</h4>
              </div>
              <StyledFormInputDiv>
                <div style={{ width: "50%" }}>
                  <StyledLabel htmlFor="qdesc">Custom Topic Title</StyledLabel>
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    maxLength={50}
                    style={{
                      width: "100%",
                      // marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Custom Topic Title"
                    value={customTopicTitle}
                    // disabled={true}

                    onChange={(e) => {
                      setCustomTopicTitle(e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.customTopicTitle_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginLeft: "-30%",
                    }}
                  >
                    {formErrors.customTopicTitle_error}
                  </p>
                </div>
              )}
              <StyledFormInputDiv>
                <div style={{ width: "50%" }}>
                  <StyledLabel htmlFor="qdesc">
                    Custom Topic Summary
                  </StyledLabel>
                </div>
                <div style={{ width: "100%" }}>
                  <textarea
                    type="text"
                    style={{
                      width: "100%",
                      //  marginLeft: "30%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    placeholder="Enter Custom Topic Summary"
                    value={customTopicSummary}
                    maxLength={300}
                    onChange={(e) => {
                      setCustomTopicSummary(e.target.value);
                      // setNumLength(e.target.value.length)
                      console.log(e.target.value);
                    }}
                    // onKeyPress={numLength == 250 ? message.error("Max characters limit is 300") : ""}
                  />
                </div>
              </StyledFormInputDiv>
              {formErrors.customTopicSummary_error && (
                <div className="offset-6 mt-2">
                  <p
                    style={{
                      color: "red",
                      fontSize: ".8rem",
                      marginLeft: "-30%",
                    }}
                  >
                    {formErrors.customTopicSummary_error}
                  </p>
                </div>
              )}
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "50%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                if (handleValidation()) {
                  AddNewCustomTopic();
                } else {
                  message.error("Please complete all data");
                }
              }}
            >
              Save New Custom Topic
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                resetFields();
                setAdd_custom_topic_data_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>

        {/* --------------- Edit Status Modal ------------ */}
        <ReactModal
          className="ModalStyle1 Width90"
          isOpen={custom_topic_status_modal}
        >
          <div>
            {" "}
            <StyledForm>
              {" "}
              <div align="center" className="mb-3">
                <h4>Edit Status</h4>
              </div>
              <StyledFormInputDiv>
                <div style={FormDivStyle}>
                  <StyledLabel htmlFor="qdesc">Custom Topic Status</StyledLabel>
                </div>
                <div style={{ width: "100%", alignSelf: "center" }}>
                  <select
                    style={{
                      width: "100%",
                      alignItems: "center",
                      border: "1px solid black",
                    }}
                    value={topicStatus}
                    onChange={(e) => {
                      setTopicStatus(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option hidden>Select Status</option>
                    {customTopicStatus &&
                      customTopicStatus?.map((data, index) => {
                        return (
                          <option value={data.tstatusid} label={data.tstatusid}>
                            {data.tstatusid}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </StyledFormInputDiv>
            </StyledForm>
          </div>
          <div align="center">
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                marginTop: 50,
              }}
              onClick={() => {
                // if (handleValidation()) {
                UpdateCustomTopicStatus();
                // } else {
                //   message.error("Please complete all data");
                // }
              }}
            >
              Save Status
            </StyledButton>
            <StyledButton
              style={{
                width: "30%",
                height: 30,
                backgroundColor: "red",
                marginLeft: 10,
              }}
              onClick={() => {
                setCustom_topic_status_modal(false);
              }}
            >
              Cancel
            </StyledButton>
          </div>
        </ReactModal>

        {/* modal for status info */}
        <ReactModal className="ModalStyle3 Width90" isOpen={status_info_modal}>
          <div className="text-center pb-3">
            <a
              onClick={() => {
                setStatus_info_modal(false);
              }}
            >
              <i
                class="fas fa-times-circle"
                style={{ color: "red", fontSize: 40}}
              ></i>
            </a>
          </div>
          <StyledTable class="table-auto">
            <thead>
              <StyledTableHeader>
                <StyledTableHeaderData>S.No</StyledTableHeaderData>
                <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                  Description
                </StyledTableHeaderData>
                <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                  Status
                </StyledTableHeaderData>
                <StyledTableHeaderData>Editable</StyledTableHeaderData>
              </StyledTableHeader>
            </thead>
            <tbody>
              {infoStatus?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>

                    <td style={{ color: "black" }}>{data.tstatusdesc}</td>
                    <td style={{ color: "black" }}>{data.tstatusid}</td>
                    <td style={{ color: "black" }}>{data.custedit}</td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </ReactModal>

        {/* ------------------ Continue & Begin Modal ----------------- */}
        <ReactModal
          isOpen={cb_Modal}
          className="ModalStyle1 Width90"
          onRequestClose={() => {
            setCb_Modal(false);
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            {status_Masaage == "completed" && (
              <h4>This topic test was completed during the last test drive.</h4>
            )}
            {status_Masaage == "Inprocess" && (
              <h4>This topic test is in process.</h4>
            )}
            {status_Masaage == "noTopic" && <h4>Start the topic test</h4>}
          </div>
          <div align="center">
            {(status_Masaage == "completed" ||
              status_Masaage == "Inprocess") && (
              <StyledButtonCB
                onClick={() => {
                  history.push("/test/" + getTopicid + "/deletetest");
                }}
              >
                Delete previous test and restart
              </StyledButtonCB>
            )}
          </div>
          <div align="center">
            <StyledButtonCB
              onClick={() => {
                history.push("/test/" + getTopicid + "/continuetest");
              }}
            >
              Start/Continue
            </StyledButtonCB>
          </div>
          <div align="center">
            <StyledButtonCB
              style={{ background: "red" }}
              onClick={() => {
                setCb_Modal(false);
              }}
            >
              Cancel
            </StyledButtonCB>
          </div>
        </ReactModal>
      </div>




      <div
              style={{ height: '78vh', width: "100%", marginTop: 30 , overflow: 'hidden'}}
              className={classes.root}
            >
              <DataGrid
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "#ff8c24",
                  },
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                }}
                rows={rows1}
                getRowId={(r) => r.id}
                id="_id"
                columns={columnsUsers}
                // initialState={{

                //   pagination: {
                //     pageSize: 10,
                //   },
                // }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[ 100]}
                hideFooterPagination={rows1.length <= pageSize}
                pagination
                //  processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />

            </div>



      {/* <div className=" mt-5 table-responsive tableFixHeadXLarge">
        <StyledTable>
          <thead>
            <ScrollTableHeader>
              <StyledTableHeaderData>S.No</StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Custom Topic Title
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Custom Topic Summary
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Status
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: 15 }}>
                Action
              </StyledTableHeaderData>
              <StyledTableHeaderData style={{ paddingLeft: "4%" }}>
                Start/Test
              </StyledTableHeaderData>
            </ScrollTableHeader>
          </thead>

          <tbody>
            {customTopicData &&
              customTopicData?.map((data, index) => (
                <StyledTableRow>
                  <td style={{ color: "black" }}>{index + 1}</td>
                  <td
                    style={{
                      color: "black",
                      // wordBreak: "break-word",
                      width: "20%",
                    }}
                  >
                    {data.cuzdesc}
                  </td>
                  <td
                    style={{
                      color: "black",
                      // wordBreak: "break-word",
                      width: "30%",
                    }}
                  >
                    {data.cuzsummary}
                  </td>
                  <td style={{ color: "black" }}>
                    {data.tstatusid}
                    <a
                      onClick={() => {
                        setTopicId(data.topicid);
                        setTopicStatus(data.tstatusid);
                        setCustom_topic_status_modal(true);
                        console.log("status set ====>", topicStatus, topicId);
                      }}
                    >
                      &nbsp;
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        setCustomTopicTitle(data.cuzdesc);
                        setCustomTopicSummary(data.cuzsummary);
                        setTopicId(data.topicid);
                        setEdit_custom_topic_data_modal(true);
                        console.log("========", statusEditPermission);
                      }}
                    >
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                    <a
                      onClick={() => {
                        setStatus_info_modal(true);
                        ReceiveStatusInfo();
                      }}
                    >
                      <i
                        class="fas fa-info-circle"
                        style={{ marginLeft: 5 }}
                      ></i>
                    </a>
                  </td>
                  <td>
                    <StyledButtonST
                      onClick={() => {
                        history.push(`/ExtQuestion/${data.topicid}`);
                      }}
                    >
                      Start
                    </StyledButtonST>
                    &nbsp;&nbsp;
                    <StyledButtonST
                      onClick={() => {
                        show_CB_Modal(data);
                      }}
                    >
                      Test
                    </StyledButtonST>
                  </td>
                </StyledTableRow>
              ))}
          </tbody>
        </StyledTable>
      </div> */}
    </div>
  );
};

export default Custom_Topic_Data;
